/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from '../css/pages/SaltedgeSuccess.module.scss';

import SEO from '../components/layout/SEO';

const SaltedgeSuccess = (props) => {
    const spinner = '../images/spinner.gif';

    const redirect = props.location.search
        ? `https://app.roundups.org/dashboard/saltedge-success/${props.location.search}`
        : // ? `http://localhost:3000/dashboard/saltedge-success/${props.location.search}`
          'https://app.roundups.org/dashboard';

    setTimeout(() => {
        if (typeof window !== 'undefined') {
            // const now = new Date().valueOf();
            // setTimeout(() => {
            //     if (new Date().valueOf() - now > 100) return;
            window.location.href = redirect;
            // }, 25);
            // window.location = 'roundups://?action=login';
            // window.location = `exp://7h-2vm.roundups.mobile-app.exp.direct:80${props.location.search}&action=saltedge`;
        }
    }, 250);

    return (
        <>
            <SEO title="Redirecting to Ripples..." allowIndexing={false} />
            <section className={styles.SaltedgeSuccess}>
                <StaticImage src={spinner} alt="spinner" />
            </section>
        </>
    );
};

export default SaltedgeSuccess;
